<template>
  <Container :vertical-padding="false" :horizontal-padding="false">
    <div class="header-container">
      <div
        class="header"
        :class="[
          !backgroundColor && 'header-bleed',
          lightText && 'header-white',
        ]"
      >
        <div class="header-text" :style="{ backgroundColor: backgroundColor }">
          <h1 v-if="headline" class="header-text-headline">{{ headline }}</h1>
          <div v-if="paragraph" class="header-text-paragraph">
            {{ paragraph }}
          </div>
          <div v-if="terms" class="header-text-terms">{{ terms }}</div>
          <div v-if="buttonLabel" class="header-text-button">
            <Button
              size="large"
              :to="buttonHref"
              :color="lightText ? 'gray' : 'red'"
              :layout="lightText ? 'inverted' : 'default'"
              :style="lightText ? { color: backgroundColor } : {}"
            >
              {{ buttonLabel }}
            </Button>
          </div>
        </div>
        <div class="header-image">
          <picture>
            <source
              v-for="imgType in mobileImagesByType"
              :key="`mobile${imgType.type}`"
              :srcset="imgType.srcsetstring"
              :type="imgType.type"
              media="(max-width: 659px)"
            />
            <source
              v-for="imgType in desktopImagesByType"
              :key="`desktop${imgType.type}`"
              :srcset="imgType.srcsetstring"
              sizes="(min-width: 660px) 660px"
              :type="imgType.type"
            />
            <img :alt="alt" aria-hidden="true" :src="src" />
          </picture>
        </div>
      </div>
    </div>
  </Container>
</template>

<script lang="ts" setup>
  const props = defineProps<{
    src: string
    alt: string
    headline: string
    paragraph: string
    terms: string
    buttonHref: string
    buttonLabel: string
    backgroundColor: string
    lightText: boolean
    desktopSrcset: ImageSource[]
    mobileSrcset: ImageSource[]
  }>()

  const mobileImagesByType = computed(() => useImageFormats(props.mobileSrcset))
  const desktopImagesByType = computed(() =>
    useImageFormats(props.desktopSrcset),
  )
</script>

<style lang="scss" scoped>
  .header-container {
    --header-radius: #{$border-radius-large};
    --header-margin: #{$gutter};
    --header-columns: clamp(0px, 620px, 60%) 1fr;
    --header-rows: 1fr;
    --header-padding: #{scalepx($base-spacing * 10, $base-spacing * 18)};
    --header-padding-bottom-sm: #{scalepx(
        $base-spacing * 10,
        $base-spacing * 16
      )};
    --header-overlap: #{$border-radius-large * -1};
    --header-color: initial;
    --header-margins: #{$base-spacing * 4};
    margin: 0 var(--header-margin) $base-spacing * 4;
    @include viewport("mini") {
      --header-radius: 0;
      --header-margin: 0;
      --header-columns: 1fr;
      --header-rows: 1fr 56.25vw;
      --header-overlap: 0;
      --header-margins: #{$base-spacing * 2};
    }
  }

  .header {
    width: 100%;
    display: grid;
    contain: paint;
    border-radius: var(--header-radius);
    grid-template-columns: var(--header-columns);
    grid-template-rows: var(--header-rows);

    &-bleed {
      --header-overlap: 0;
      --header-padding-bottom-sm: 0;
    }
    &-white {
      color: white;
    }

    &-text {
      border-radius: var(--header-radius);
      z-index: 2;
      grid-row: 1;
      grid-column: 1;
      padding: var(--header-padding);
      @include compact;
      @include bookend;
      @include viewport("mini") {
        padding-bottom: var(--header-padding-bottom-sm);
      }
      > * {
        margin-bottom: var(--header-margins);
      }
      &-headline {
        @include type(h1);
      }
      &-paragraph {
        @include type(lead);
      }
      &-terms {
        @include type(xs);
      }
      &-button {
        margin-top: $base-spacing * 6;
      }
    }
    &-image {
      margin-left: var(--header-overlap);
      position: relative;
      picture,
      img {
        @include fill;
      }
      .header-bleed & {
        grid-row: 1;
        grid-column: 1 / span 2;
        @include viewport("mini") {
          grid-column: 1;
          grid-row: 1 / span 2;
        }
      }
    }
  }
</style>
